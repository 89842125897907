/* eslint-disable react/prop-types */
// importSource //

import React, { useState } from "react";
import { navigate } from "gatsby";
import tw, { css } from "twin.macro";
import { useAuth, useTheme } from "~hooks";
import { Grid } from "~components";
import { getColor, validateEmail } from "~utils/helpers";
import { MEDIA_QUERIES } from "~utils/css";

import logo from "~assets/icons/huge-logo.svg";

const LoginForm = () => {
  const { login, resetEmailPassword } = useAuth();
  const { colours, colourTransitionCSS, lowImpactMode } = useTheme();

  const [formError, setFormError] = useState(null);
  const [status, setStatus] = useState({
    submitting: false,
    submitted: false,
    resetSubmitting: false
  });
  const [passwordReset, setPasswordReset] = useState(false);

  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [resetEmail, setResetEmail] = useState("");

  const handleResetEmailChange = (e) => {
    setResetEmail(e.target.value);
  };

  const handleLoginEmailChange = (e) => {
    setLoginEmail(e.target.value);
  };

  const handleLoginPasswordChange = (e) => {
    setLoginPassword(e.target.value);
  };

  const valid = () => validateEmail(loginEmail) && loginPassword !== ``;
  const resetValid = () => validateEmail(resetEmail);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!valid()) {
      setStatus({
        submitting: false,
        submitted: false
      });

      return false;
    }

    setStatus({
      submitting: true,
      submitted: false
    });

    const result = await login({ email: loginEmail, password: loginPassword });

    if (result !== true) {
      console.log(loginEmail);
      setFormError(result);

      setStatus({
        submitting: false,
        submitted: false
      });

      return false;
    }

    setStatus({
      submitting: false,
      submitted: true
    });

    return true;
  };

  const onPasswordReset = async (e) => {
    e.preventDefault();

    if (!resetValid()) {
      setStatus({
        resetSubmitting: false
      });

      return false;
    }

    setStatus({
      resetSubmitting: true
    });

    const result = await resetEmailPassword(resetEmail);

    if (result !== true) {
      setFormError(result);

      setStatus({
        resetSubmitting: false
      });

      return false;
    }

    setStatus({
      resetSubmitting: false
    });

    return true;
  };

  const togglePasswordReset = () => {
    setPasswordReset(!passwordReset);

    setFormError(null);
  };

  //

  return (
    <div css={[tw`w-full h-full relative`]}>
      <div
        css={[
          css`
            ${colourTransitionCSS};

            background: ${lowImpactMode
              ? colours.foreground
              : colours.background};
          `,
          tw`w-full h-full absolute top-0 right-0 bottom-0 left-0 z-10`
        ]}
      >
        <img
          css={[tw`w-full h-full relative block object-cover`]}
          src={logo}
          alt="Huge logo"
        />
      </div>

      <div
        css={[
          tw`w-full h-full relative z-20 flex items-center justify-between`
        ]}
      >
        <Grid>
          <div
            css={[
              tw`col-span-12 h-full relative flex flex-col items-center justify-center`
            ]}
          >
            <div
              css={[
                css`
                  ${colourTransitionCSS};

                  width: 100%;
                  background: ${lowImpactMode
                    ? colours.background
                    : colours.foreground};
                  color: ${lowImpactMode
                    ? colours.foreground
                    : colours.background};

                  ${MEDIA_QUERIES.desktop} {
                    width: 40%;
                  }
                `,
                tw`relative block px-3 py-6 md:p-8 rounded-xl`
              ]}
            >
              <h2 css={[tw`mb-3 md:mb-1 font-head text-b1 md:text-h3-md`]}>
                {passwordReset ? "Reset your password" : "Login to The WIP"}
              </h2>

              <p
                css={[
                  tw`w-full md:min-h-[1.75rem] mb-4 font-body text-b3 md:text-b2-md text-green-dark`
                ]}
              >
                {formError || ``}
              </p>

              {passwordReset ? (
                <form onSubmit={onPasswordReset}>
                  <label css={[tw`relative block mb-6`]}>
                    <span
                      css={[
                        tw`block mb-2 font-body italic text-b3 md:text-b2-md`
                      ]}
                    >
                      Email
                    </span>

                    <input
                      id="reset_email"
                      name="resetEmail"
                      onChange={handleResetEmailChange}
                      value={resetEmail}
                      on
                      autoComplete={false}
                      css={[
                        tw`w-full h-10 md:h-12 relative block mb-4 px-4 rounded-md font-body text-b3 md:text-b2-md`
                      ]}
                      placeholder="Please enter your email"
                      readOnly={status?.resetSubmitting}
                      required
                    />
                  </label>

                  <button
                    css={[tw`mb-6`]}
                    type="button"
                    onClick={() => togglePasswordReset()}
                  >
                    <p css={[tw`font-body text-b3-md`]}>Back to Login</p>
                  </button>

                  <input
                    css={[
                      css`
                        ${colourTransitionCSS};

                        cursor: pointer;
                        background: ${lowImpactMode
                          ? colours.foreground
                          : colours.background};
                        color: ${lowImpactMode
                          ? colours.background
                          : colours.foreground};
                        border: 1px solid
                          ${lowImpactMode
                            ? colours.foreground
                            : colours.background};

                        ${MEDIA_QUERIES.hoverable} {
                          &:hover {
                            background: ${lowImpactMode
                              ? colours.background
                              : colours.foreground};
                            color: ${lowImpactMode
                              ? colours.foreground
                              : colours.background};
                          }
                        }
                      `,
                      tw`w-40 relative block pt-3 pb-2 overflow-hidden rounded-lg text-b3 md:text-b2-md font-body`
                    ]}
                    value="Send email"
                    type="submit"
                  />
                </form>
              ) : (
                <form onSubmit={onSubmit}>
                  <label htmlFor="email" css={[tw`relative block mb-6`]}>
                    <span
                      css={[
                        tw`block mb-2 font-body italic text-b3 md:text-b2-md`
                      ]}
                    >
                      Email
                    </span>

                    <input
                      id="login_email"
                      name="email"
                      autoComplete={false}
                      css={[
                        tw`w-full h-10 md:h-12 relative block mb-4 px-4 rounded-md font-body text-b3 md:text-b2-md`
                      ]}
                      onChange={handleLoginEmailChange}
                      value={loginEmail}
                      placeholder="Please enter your email"
                      readOnly={status?.submitting || status?.submitted}
                      required
                      type="email"
                    />
                  </label>

                  <label htmlFor="password" css={[tw`relative block mb-6`]}>
                    <span
                      css={[
                        tw`block mb-2 font-body italic text-b3 md:text-b2-md`
                      ]}
                    >
                      Password
                    </span>

                    <input
                      id="login_password"
                      name="password"
                      autoComplete={false}
                      css={[
                        tw`w-full h-10 md:h-12 relative block mb-4 px-4 rounded-md font-body text-b3 md:text-b2-md`
                      ]}
                      onChange={handleLoginPasswordChange}
                      value={loginPassword}
                      placeholder="********"
                      readOnly={status?.submitting || status?.submitted}
                      required
                      type="password"
                    />
                  </label>

                  <button
                    css={[tw`mb-6`]}
                    type="button"
                    onClick={() => togglePasswordReset()}
                  >
                    <p css={[tw`font-body text-b3-md`]}>
                      Forgot your password?
                    </p>
                  </button>

                  <input
                    css={[
                      css`
                        ${colourTransitionCSS};

                        cursor: pointer;
                        background: ${lowImpactMode
                          ? colours.foreground
                          : colours.background};
                        color: ${lowImpactMode
                          ? colours.background
                          : colours.foreground};
                        border: 1px solid
                          ${lowImpactMode
                            ? colours.foreground
                            : colours.background};

                        ${MEDIA_QUERIES.hoverable} {
                          &:hover {
                            background: ${lowImpactMode
                              ? colours.background
                              : colours.foreground};
                            color: ${lowImpactMode
                              ? colours.foreground
                              : colours.background};
                          }
                        }
                      `,
                      tw`w-24 relative block pt-3 pb-2 overflow-hidden rounded-lg text-b3 md:text-b2-md font-body`
                    ]}
                    value="Login"
                    type="submit"
                  />
                </form>
              )}
            </div>
          </div>
        </Grid>
      </div>
    </div>
  );
};

export default LoginForm;
